import React from "react";
import { Link as ScrollLink } from "react-scroll";

const aboutData = {
  cvpath: "media/Fares Hmedan-Resume.pdf",
  image: "images/about.png",
  name: "Fares Hmedan",
  location: "Abu Dhabi, UAE",
  birthday: "07 April, 1990",
  email: "fareshmedan@gmail.com",
  aboutMe:
    "With a bachelor's degree in Information and Communication Engineering as a software engineer, hands-on experience of 5 years in programming languages and frameworks as (Java, Android, PHP, Laravel, Flutter, .Net, C#, HTML, CSS, JavaScript) and IT tasks, to create and implement software, mobile, and web applications, and having the ability to work within a team, withstand the pressure of work, and possess the imagination to explore solutions, I would like to gain and share the computer science experiences.",
};

function About() {
  return (
    <div className="row">
      <div className="col-md-3">
        <img src={aboutData.image} alt={aboutData.name} />
      </div>
      <div className="col-md-9">
        <h2 className="mt-4 mt-md-0 mb-4">Hello,</h2>
        <p className="mb-0">{aboutData.aboutMe}</p>
        <div className="row my-4">
          <div className="col-md-6">
            <p className="mb-2">
              Name: <span className="text-dark">{aboutData.name}</span>
            </p>
            <p className="mb-0">
              Birthday: <span className="text-dark">{aboutData.birthday}</span>
            </p>
          </div>
          <div className="col-md-6 mt-2 mt-md-0 mt-sm-2">
            <p className="mb-2">
              Location: <span className="text-dark">{aboutData.location}</span>
            </p>
            <p className="mb-0">
              Email: <span className="text-dark">{aboutData.email}</span>
            </p>
          </div>
        </div>
        <a href={aboutData.cvpath} className="btn btn-default mr-3">
          <i className="icon-cloud-download"></i>Download CV
        </a>
        <ScrollLink
          activeClass="active"
          to="section-contact"
          spy={true}
          smooth={true}
          duration={500}
          offset={50}
          className="btn btn-alt mt-2 mt-md-0 mt-xs-2"
        >
          <i className="icon-envelope"></i>Hire me
        </ScrollLink>
      </div>
    </div>
  );
}

export default About;
