import React from "react";
import Experience from "../Items/Experience";

const experiencesData = [
  {
    id: 1,
    year: "2021 - Present",
    degree: "Web Developer",
    content:
      "PHP, Sitecore, HTML, CSS, Wordpress",
  },
  {
    id: 2,
    year: "2017 - 2021",
    degree: "Freelance Developer",
    content:
      "Flutter, PHP, Wordpress",
  },
  {
    id: 3,
    year: "2017 - 2021",
    degree: "Web Developer, IT Engineer",
    content:
      "PHP, Wordpress",
  },
  {
    id: 4,
    year: "2014 - 2017",
    degree: "Java/Android, Web Developer",
    content:
      "Java, ADK, PHP",
  },
  {
    id: 5,
    year: "2014 - 2014",
    degree: "Project Engineer",
    content:
      "CCTV, Security Systems",
  },
];

function Experiences() {
  return (
    <div className="timeline">
      {experiencesData.map((experience) => (
        <Experience experience={experience} key={experience.id} />
      ))}
      <span className="timeline-line"></span>
    </div>
  );
}

export default Experiences;
